import { Avatar, Box, Button, Paper, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginRequest } from 'redux/reducers/auth/reducer';

export const LoginForm: FC = () => {
	const dispatch = useDispatch();

	const [login, setLogin] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	// const EMAIL_REGEXP =
	// 	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

	// const isEmailValid = () => {
	// 	if (email === null) return true;
	// 	return EMAIL_REGEXP.test(email ?? '');
	// };

	const handleLoginSubmit = () => {
		dispatch(loginRequest({ login: login ?? '', password: password ?? '' }));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				backgroundColor: '#016193',
				height: '100vh',

				'& > :not(style)': {
					m: 1,
					width: '300px',
					height: '420px',
					marginTop: '20vh',
					padding: '20px',
				},
			}}
		>
			<Paper>
				<p style={{ display: 'flex', justifyContent: 'center', marginTop: 0, height: '90px' }}>
					<img alt="MCI" src="/img/EAS-project-logo.svg" />
				</p>
				<h2 style={{ textAlign: 'center', color: '#004368' }}>
					Sign in{'  '}
					<Typography
						variant="h6"
						noWrap
						component="a"
						sx={{
							ml: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
							fontSize: 30,
						}}
					>
						MTD
					</Typography>
				</h2>
				<TextField
					label="login"
					type="text"
					variant="outlined"
					sx={{ marginTop: '20px' }}
					value={login}
					onChange={(e) => setLogin(e.target.value)}
					fullWidth
					// error={!isEmailValid()}
					error={login.length > 0 && login.length < 4}
				/>
				<TextField
					label="password"
					type="password"
					variant="outlined"
					sx={{ marginTop: '20px' }}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					fullWidth
					error={password.length > 0 && password.length < 4}
				/>
				<Button
					size="large"
					sx={{ marginTop: '50px', width: '100%' }}
					variant="contained"
					autoFocus
					onClick={handleLoginSubmit}
					disabled={login.length < 4 || password.length < 4}
				>
					Sign in
				</Button>
			</Paper>
		</Box>
	);
};
