/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from 'react';
import { nanoid } from 'nanoid';
import quarterLevel from 'data/quarter-level.json';
import emLevelFilters from 'data/em-level-filters.json';
import monthReport from 'data/month-report.json';
import monthReportTable from 'data/month-report-table.json';
import emLevel from 'data/em-level.json';
import emLevelTable from 'data/em-level-table.json';
import quarterLevelTable from 'data/quarter-level-table.json';
import easQuarterLevelTable from 'data/eas-quarter-level-table.json';
import monthReportFilters from 'data/month-report-filters.json';
import quarterLevelFilters from 'data/quarter-level-filters.json';
import easQuarterLevelFilters from 'data/eas-quarter-level-filters.json';
import { MonthReportTable } from './MonthReportTable';
import { Navigator } from './Navigator';

export const SubmitVATReport: FC = () => {
	const [tableType, setTableType] = useState('');

	return (
		<div style={{ marginTop: '40px', display: 'flex', width: '100%' }}>
			<Navigator setTableType={setTableType} />
			<div style={{ width: '100%' }}>
				{tableType === 'month report' ? (
					<MonthReportTable
						key={nanoid()}
						data={monthReport}
						tableStructure={monthReportTable}
						tableType={tableType}
						tableFilters={monthReportFilters}
					/>
				) : tableType === 'EAS number quarter level' ? (
					<MonthReportTable
						key={nanoid()}
						data={quarterLevel}
						tableStructure={easQuarterLevelTable}
						tableType={tableType}
						tableFilters={easQuarterLevelFilters}
					/>
				) : tableType === 'quarter level' ? (
					<MonthReportTable
						key={nanoid()}
						data={quarterLevel}
						tableStructure={quarterLevelTable}
						tableType={tableType}
						tableFilters={quarterLevelFilters}
					/>
				) : tableType === 'em level' ? (
					<MonthReportTable
						key={nanoid()}
						data={emLevel}
						tableStructure={emLevelTable}
						tableType={tableType}
						tableFilters={emLevelFilters}
					/>
				) : (
					''
				)}
			</div>
		</div>
	);
};
