/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
import { Paper, Typography } from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'ui/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionGray, AccordionSummaryGray } from 'ui/StyledAccordion';
import settingsJSON from 'data/navigator.json';
import { IFoldersType, TSubmitVATReportListProps } from './types';
import { AccordionLevel } from './AccordionLevel';

export const SubmitVATReportList: FC<TSubmitVATReportListProps> = ({
	setTableType,
	setPathName,
	pathName,
	path,
	setPath,
}) => {
	const params = useParams<{ level1?: string; level2?: string; level3?: string }>();
	const { level1, level2, level3 } = params;

	const navigate = useNavigate();

	const settings = settingsJSON as IFoldersType[];

	const settingsIsLoad = false;

	const [cellValue, setCellValue] = useState<string | number | boolean>('');
	const [editCell, setEditCell] = useState(false);

	const [accordion1Expanded, setAccordion1Expanded] = useState<string | false>(false);
	const [accordion2Expanded, setAccordion2Expanded] = useState<string | false>(false);
	const [accordion3Expanded, setAccordion3Expanded] = useState<string | false>(false);

	const handleCancelChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
	};

	const handleAccordion1Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			// setAccordion1Expanded(isExpanded ? panel : false);
			// setAccordion2Expanded(false);
			// setAccordion3Expanded(false);
			// handleCancelChangeCellValue();
			navigate(`/${settings[path[0]].field}`);
		};
	const handleNavigate = (p: string) => {
		// navigate(`/${p}`);
	};
	const handleAccordion2Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion2Expanded(isExpanded ? panel : false);
			setAccordion3Expanded(false);
			handleCancelChangeCellValue();
		};
	const handleAccordion3Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion3Expanded(isExpanded ? panel : false);
			handleCancelChangeCellValue();
		};

	useEffect(() => {
		if (level1) {
			const index1 = settings.findIndex((item) => item.field === level1);
			setAccordion1Expanded(level1);
			setPath([index1]);
			setPathName([settings[index1].name]);
			setTableType(settings[index1].tableType);

			if (level2) {
				const index2 =
					settings?.[index1]?.children?.findIndex((item) => item.field === level2) ?? 0;
				setAccordion2Expanded(level2);
				setPath([index1, index2]);
				setPathName([settings[index1].name, settings[index1]?.children?.[index2]?.name ?? '']);
				setTableType(settings[index1]?.children?.[index2]?.tableType ?? '');

				if (level3) {
					const index3 =
						settings?.[index1]?.children?.[index2]?.children?.findIndex(
							(item) => item.field === level3,
						) ?? 0;
					setAccordion3Expanded(level3);
					setPath([index1, index2, index3]);
					setPathName([
						settings[index1].name,
						settings[index1]?.children?.[index2]?.name ?? '',
						settings[index1]?.children?.[index2]?.children?.[index3]?.name ?? '',
					]);
					setTableType(settings[index1]?.children?.[index2]?.children?.[index3]?.tableType ?? '');
				}
			}
		}
	}, [level1, level2, level3, settings]);

	return (
		<>
			{!settings && !settingsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No settings found...</p>
				</div>
			)}

			{settingsIsLoad && <Loader />}

			{!!settings && !settingsIsLoad && (
				<Paper sx={{ ml: '-24px' }}>
					<div>
						{settings.map((item, index) => (
							<AccordionGray
								key={item.field}
								expanded={accordion1Expanded === item.field}
								// onChange={handleAccordion1Change(item.field)}
							>
								<AccordionSummaryGray
									expandIcon={<ExpandMoreIcon />}
									onClick={() => {
										// setTableType(item.tableType);
										// setPathName([item.name]);
										// setPath([index]);
										// navigate({
										// 	pathname: `/${settings[index].field}`,
										// 	// search: '',
										// });
										navigate(`/${settings[index].field}`);
									}}
									style={{
										backgroundColor:
											pathName.length === 1 && pathName[0] === item.name ? '#d8f2ff' : '#f3f3f3',
									}}
								>
									<Typography sx={{ flexShrink: 0 }}>{item.name}</Typography>
								</AccordionSummaryGray>
								<AccordionDetails>
									<div
										style={{
											borderLeft: '1px solid #ff840347',
											height: 'calc(100% - 78px)',
											position: 'absolute',
											marginTop: '-16px',
											marginLeft: '15px',
										}}
									/>
									{(item?.children?.length ?? 0) > 0 &&
										item?.children?.map((datum0Item, datum0Index) => (
											<AccordionLevel
												key={datum0Item.field}
												level={1}
												index={datum0Index}
												data={datum0Item?.children}
												accordion2Expanded={accordion2Expanded}
												accordion3Expanded={accordion3Expanded}
												name={datum0Item.name}
												description={datum0Item.description}
												type={datum0Item.type}
												handleAccordion2Change={handleAccordion2Change}
												handleAccordion3Change={handleAccordion3Change}
												tableType={datum0Item.tableType}
												setTableType={setTableType}
												setPathName={setPathName}
												pathName={pathName}
												path={path}
												setPath={setPath}
											/>
										))}{' '}
								</AccordionDetails>
							</AccordionGray>
						))}
					</div>
				</Paper>
			)}
		</>
	);
};
