/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { SubmitVATReportList } from './SubmitVATReportList';
import { TNavigatorProps } from './types';

export const Navigator: FC<TNavigatorProps> = ({ setTableType }) => {
	const [open, setOpen] = useState(false);

	const [field, setField] = useState('');
	const [pathName, setPathName] = useState<string[]>([]);
	const [path, setPath] = useState<number[]>([]);

	const [secondsPanel, setSecondsPanel] = useState(0);
	const [isActiveTimerPanel, setIsActiveTimerPanel] = useState(false);

	const handleStartTimerPanel = () => {
		setIsActiveTimerPanel(true);
		setSecondsPanel(0);
	};

	const handlePauseTimerPanel = () => {
		setIsActiveTimerPanel(false);
	};

	const handleOpen = () => {
		setOpen(true);
		handleStartTimerPanel();
	};
	const handleClose = () => {
		setOpen(false);
		handlePauseTimerPanel();
	};

	const setPathNameHandle = (p: string[]) => {
		setPathName(p);
		handleStartTimerPanel();
	};

	useEffect(() => {
		let interval: any = null;

		if (isActiveTimerPanel) {
			interval = setInterval(() => {
				setSecondsPanel((sec) => sec + 1);
			}, 1000);
			if (secondsPanel > 6) {
				setIsActiveTimerPanel(false);
				setSecondsPanel(0);
				handleClose();
			}
		} else {
			clearInterval(interval);
		}

		return () => {
			clearInterval(interval);
		};
	}, [isActiveTimerPanel, secondsPanel]);

	return (
		<div>
			<div
				style={{
					width: '30px',
					// height: '100%',
					backgroundColor: '#016193',
					marginTop: '-24px',
					// marginLeft: '-20px',
					paddingTop: '20px',
					cursor: 'pointer',
					display: `${open ? 'none' : 'block'}`,
					borderRight: '1px solid #fff',
					height: 'calc(100vh - 100px)',
				}}
				className="icon-hover-rotate-container"
				onClick={handleOpen}
			>
				<div className="icon-hover-rotate icon-hover-rotate--minimized">
					<MenuIcon style={{ color: '#fff' }} />
				</div>
				<div style={{ writingMode: 'vertical-lr', color: '#fff', margin: '10px 5px' }}>
					{pathName.map((p, i) => (
						<span key={p} style={{ fontWeight: i === 0 ? 'bold' : 'auto' }}>
							{' '}
							/ {p}
						</span>
					))}
				</div>
			</div>
			<div
				style={{
					backgroundColor: '#4f8fb0',
					zIndex: 1000,
					display: `${open ? 'block' : 'none'}`,
					paddingLeft: '20px',
					height: 'calc(100vh - 80px)',
					minWidth: '450px',
				}}
			>
				<div
					style={{
						backgroundColor: '#016193',
						color: '#fff',
						marginTop: '-24px',
						marginLeft: '-20px',
						padding: '1.5px 10px',
						cursor: 'pointer',
						borderRight: '1px solid #fff',
					}}
					onClick={handleClose}
					className="icon-hover-rotate-container"
				>
					<div className="icon-hover-rotate">
						<MenuIcon style={{ color: '#fff' }} />
					</div>
				</div>
				<SubmitVATReportList
					setTableType={setTableType}
					setPathName={setPathNameHandle}
					pathName={pathName}
					path={path}
					setPath={setPath}
				/>
			</div>
		</div>
	);
};
