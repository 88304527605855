import SpeedDialAction from '@mui/material/SpeedDialAction';
import { FC, SyntheticEvent, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import { TActionSpeedDialProps } from './types';
import { FilterField } from './FilterField';

export const ActionSpeedDial: FC<TActionSpeedDialProps> = ({
	filterable,
	sortable,
	filterItem,
	filterList,
	onChangeFilter,
	onChangeSort,
	sortDirection,
}) => {
	const [open, setOpen] = useState(false);
	const [filterListOpen, setFilterListOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleFilterListOpen = (e: SyntheticEvent) => {
		e.stopPropagation();
		setFilterListOpen(!filterListOpen);
		setAnchorEl(e.target as HTMLElement);
	};

	const speedDialTooltipOpen = () => {
		setOpen(true);
	};
	const speedDialTooltipClose = () => {
		setOpen(false);
	};

	return (
		<div
			onMouseOver={speedDialTooltipOpen}
			onFocus={speedDialTooltipOpen}
			onMouseLeave={speedDialTooltipClose}
			onBlur={speedDialTooltipClose}
			style={{ position: 'absolute', right: '0', top: '0', width: '100%' }}
		>
			{filterable && (filterList?.length ?? 0) > 0 && (
				<SpeedDialAction
					key="filterable"
					icon={<FilterAltIcon />}
					tooltipTitle="Filter"
					tooltipPlacement="top-start"
					open={open}
					onClick={handleFilterListOpen}
					sx={{ m: '4px', height: '36px', width: '36px' }}
				/>
			)}
			{sortable && sortDirection !== 'desc' && (
				<SpeedDialAction
					key="sortable-down"
					icon={<ArrowDropDownIcon sx={{ fontSize: 30 }} />}
					tooltipTitle="Sort DESC"
					tooltipPlacement="top-start"
					open={open}
					sx={{ m: '4px', height: '36px', width: '36px' }}
					onClick={() => onChangeSort?.('desc')}
				/>
			)}
			{sortable && sortDirection !== 'asc' && (
				<SpeedDialAction
					key="sortable-up"
					icon={<ArrowDropUpIcon sx={{ fontSize: 30 }} />}
					tooltipTitle="Sort ASC"
					tooltipPlacement="top-start"
					open={open}
					sx={{ m: '4px', height: '36px', width: '36px' }}
					onClick={() => onChangeSort?.('asc')}
				/>
			)}
			{sortable && sortDirection && (
				<SpeedDialAction
					key="sortable-off"
					icon={<MobiledataOffIcon />}
					tooltipTitle="Sort OFF"
					tooltipPlacement="top-start"
					open={open}
					sx={{ m: '4px', height: '36px', width: '36px' }}
					onClick={() => onChangeSort?.(undefined)}
				/>
			)}

			<FilterField
				filterItem={filterItem as string}
				filterList={filterList as string[]}
				onChange={onChangeFilter as (value: string) => void}
				openList={filterListOpen}
				anchorEl={anchorEl}
			/>
		</div>
	);
};
