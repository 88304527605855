import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { AddClientDialog } from 'layouts/Header/AddClientDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { startEasSendJobRequest } from 'redux/reducers/clients/reducer';
import { useNavigate } from 'react-router-dom';
import { authInitState } from 'redux/reducers/auth/reducer';
import { SearchComponent } from 'components/SubmitVATReport/MonthReportTable/SearchComponent';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		setAnchorElUser(null);
		dispatch(authInitState());
	};

	const [openAddClientDialog, setOpenAddClientDialog] = useState(false);

	const handleOpenAddClientDialog = () => {
		handleCloseNavMenu();
		setOpenAddClientDialog(true);
	};

	const handleCloseAddClientDialog = () => {
		setOpenAddClientDialog(false);
	};
	const handleStartEasSendJob = () => {
		handleCloseNavMenu();
		dispatch(startEasSendJobRequest());
	};

	const handleToClients = () => {
		navigate('/');
	};
	const handleToVATReport = () => {
		navigate('/vat-report/new');
	};
	const handleToIframe = () => {
		navigate('/iframe');
	};
	const handleToVAT = () => {
		navigate('/vat');
	};

	const handleToSubmitVAT = () => {
		navigate('/submit-vat');
	};

	const handleToUploadCsvFile = () => {
		navigate('/upload-csv-file');
	};

	return (
		<>
			<AppBar
				position="static"
				sx={{ marginBottom: 2, backgroundColor: 'white', color: '#016193' }}
			>
				<Toolbar disableGutters sx={{ pl: '10px', pr: '10px', justifyContent: 'space-between' }}>
					{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
					<div style={{ display: 'flex' }}>
						<Avatar
							alt="EAS MTD"
							src="/img/EAS-logo-new.svg"
							sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
						/>
						<Typography
							variant="h6"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
								alignSelf: 'center',
							}}
						>
							MTD
						</Typography>

						{/* <div style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								variant="h6"
								noWrap
								component="a"
								href="/"
								sx={{
									mr: 2,
									mt: '-7px',
									mb: '-8px',
									display: { xs: 'none', md: 'flex' },
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
									fontSize: 16,
								}}
							>
								Submit
							</Typography>

							<Typography
								variant="h6"
								noWrap
								component="a"
								href="/"
								sx={{
									mr: 2,
									// mt: '-5px',
									mb: '-8px',
									display: { xs: 'none', md: 'flex' },
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
									fontSize: 16,
								}}
							>
								VAT
							</Typography>
							<Typography
								variant="h6"
								noWrap
								component="a"
								href="/"
								sx={{
									mr: 2,
									mb: '-8px',
									display: { xs: 'none', md: 'flex' },
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
									fontSize: 16,
								}}
							>
								Report
							</Typography>
						</div> */}
					</div>

					<Avatar
						alt="MTD"
						src="/img/new-logo.webp"
						sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
					/>

					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						VAT Report
					</Typography>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', justifyContent: 'normal', marginRight: '10px' }}>
							{/* <IconButton>
								<FilterListOffIcon style={{ color: '#fff' }} />
							</IconButton> */}
							<SearchComponent />
						</div>

						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar sx={{ bgcolor: '#ddd' }} src="/img/user.png" />
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem onClick={handleLogout}>
									<Typography textAlign="center">Logout</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</div>
				</Toolbar>
			</AppBar>
			<AddClientDialog open={openAddClientDialog} setClose={handleCloseAddClientDialog} />
		</>
	);
};
export default ResponsiveAppBar;
