/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { TSearchComponentProps } from './types';

export const SearchComponent: FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { search } = useLocation();
	const navigateParams = queryString.parse(search);

	const [searchStr, setSearchStr] = useState(navigateParams?.search ?? '');
	const [searchStrDebounce] = useDebounce(searchStr, 600);

	useEffect(() => {
		const newNavigateParams = { ...navigateParams };
		if (searchStrDebounce === '') {
			delete newNavigateParams.search;
		} else {
			newNavigateParams.search = searchStrDebounce;
		}

		let paramMatrix = '?';
		for (const [key, val] of Object.entries(newNavigateParams)) {
			paramMatrix += `${key}=${(val as string) ?? ''}&`;
		}
		paramMatrix = paramMatrix.slice(0, -1);

		navigate({
			pathname,
			search: paramMatrix,
		});
	}, [searchStrDebounce]);

	useEffect(() => {
		setSearchStr(navigateParams?.search ?? '');
	}, [pathname]);

	return (
		<div
			// component="form"
			color="primary"
			style={{
				padding: '1px 4px',
				display: 'flex',
				alignItems: 'center',
				width: 560,
				height: '40px',
				borderRadius: '5px',
				backgroundColor: '#fff',
				border: '1px solid #ddd',
				boxShadow: 'inset #ddd 1px 1px 3px 0px',
			}}
		>
			<SearchIcon />{' '}
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				placeholder="Search"
				inputProps={{ 'aria-label': 'search' }}
				value={searchStr}
				onChange={(e) => setSearchStr(e.target.value)}
				size="small"
				style={{ height: '20px' }}
			/>
			{/* <IconButton sx={{ p: '10px' }} aria-label="search">
				<SearchIcon />
			</IconButton> */}
			<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
			<IconButton
				color="primary"
				sx={{ p: '10px' }}
				aria-label="directions"
				onClick={() => setSearchStr('')}
			>
				<CloseIcon />
			</IconButton>
		</div>
	);
};
