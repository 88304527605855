/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetailsGreen, AccordionSummary } from 'ui/StyledAccordion';
import { FC, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import settingsJSON from 'data/navigator.json';
import { TAccordionLevelProps } from './types';
import { IFoldersType } from '../types';

export const AccordionLevel: FC<TAccordionLevelProps> = ({
	level,
	index,
	data,
	accordion2Expanded,
	accordion3Expanded,
	name,
	description,
	type,
	handleAccordion2Change,
	handleAccordion3Change,
	tableType,
	setTableType,
	setPathName,
	pathName,
	path,
	setPath,
}) => {
	const navigate = useNavigate();
	const settings = settingsJSON as IFoldersType[];

	const handleClick = (event: SyntheticEvent) => {
		console.log('============================');
		console.log('AccordionLevel handleClick level', level);
		console.log('AccordionLevel handleClick index0', path[0]);
		console.log('AccordionLevel handleClick index1', index);
		setTableType(tableType);

		// const newPathName = [];
		// for (let i = 0; i < level; i++) {
		// 	newPathName[i] = pathName[i];
		// }

		// setPathName([...newPathName, name ?? '']);
		// setPath([...path, index]);
		if (level === 1) {
			navigate(
				`/${settings[path[0]].field}/${String(
					settings?.[path[0]]?.children?.[index]?.field ?? '',
				)}`,
			);
		} else if (level === 2) {
			navigate(
				`/${settings[path[0]].field}/${String(
					settings?.[path[0]].children?.[path[1]]?.field ?? '',
				)}/${String(settings?.[path[0]].children?.[path[1]].children?.[index]?.field ?? '')}`,
			);
		}
	};

	if (type === 'folder')
		return (
			<Accordion
				expanded={
					level === 1
						? accordion2Expanded === (settings?.[path[0]]?.children?.[index].field ?? '')
						: level === 2
						? accordion3Expanded ===
						  (settings?.[path[0]]?.children?.[path[1]]?.children?.[index].field ?? '')
						: false
				}
				// onChange={
				// 	type === 'folder' && level === 1
				// 		? handleAccordion2Change(`panel2-${String(index)}`)
				// 		: handleAccordion3Change(`panel3-${String(index)}`)
				// }
				sx={{ marginLeft: '20px' }}
			>
				<AccordionSummary
					expandIcon={type === 'folder' ? <ExpandMoreIcon /> : ''}
					aria-controls="panel1bh-content"
					style={{
						backgroundColor:
							path.length === level + 1 && pathName[level] === name ? '#d8f2ff' : 'transparent',
					}}
					onClick={(e) => handleClick(e)}
				>
					<Typography sx={{ flexShrink: 0 }}>
						{name}{' '}
						{description && (
							<small style={{ color: 'gray', fontStyle: 'italic' }}>{description}</small>
						)}
					</Typography>
				</AccordionSummary>
				<AccordionDetailsGreen>
					<div
						style={{
							borderLeft: '1px solid #88cf0385',
							height: 'calc(100% - 68px)',
							position: 'absolute',
							marginTop: '-16px',
							marginLeft: '15px',
							marginBottom: '-20px',
						}}
					/>
					{(data?.length ?? 0) > 0 &&
						data?.map((item, indx) => (
							<AccordionLevel
								key={item.field}
								level={level + 1}
								index={indx}
								data={item?.children}
								accordion2Expanded={accordion2Expanded}
								accordion3Expanded={accordion3Expanded}
								name={item.name}
								description={item.description}
								type={item.type}
								handleAccordion2Change={handleAccordion2Change}
								handleAccordion3Change={handleAccordion3Change}
								tableType={item.tableType}
								setTableType={setTableType}
								setPathName={setPathName}
								pathName={pathName}
								path={path}
								setPath={setPath}
							/>
						))}
				</AccordionDetailsGreen>
			</Accordion>
		);
	return (
		<div
			style={{
				marginLeft: '40px',
				marginBottom: '10px',
				cursor: 'pointer',
				padding: '5px 10px',
				backgroundColor:
					pathName.length === level + 1 && pathName[level] === name ? '#d8f2ff' : 'transparent',
			}}
			onClick={(e) => handleClick(e)}
		>
			<Typography sx={{ flexShrink: 0 }}>
				{name}{' '}
				{description && (
					<small
						style={{
							color: 'gray',
							fontStyle: 'italic',
							cursor: 'pointer',
						}}
					>
						{description}
					</small>
				)}
			</Typography>
		</div>
	);
};
