/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import { CheckBoxOutlineBlank, CheckCircle, CheckSharp } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { useDebounce } from 'use-debounce';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { nanoid } from 'nanoid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { stringify } from 'querystring';
import { SearchComponent } from './SearchComponent';
import { TMonthReportTableProps, TSortDirection, TSortState } from './types';
import { ActionSpeedDial } from './ActionSpeedDial';

export const MonthReportTable: FC<TMonthReportTableProps> = ({
	data,
	tableStructure,
	tableType,
	tableFilters,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const navigateParams = queryString.parse(search);

	const [searchStr, setSearchStr] = useState('');
	const [searchStrDebounce] = useDebounce(searchStr, 600);
	const [filterPanelOpen, setFilterPanelOpen] = useState(false);
	const [seconds, setSeconds] = useState(0);
	const [isActiveTimer, setIsActiveTimer] = useState(false);
	// useEffect(() => {
	// 	let interval: any = null;

	// 	if (isActiveTimer) {
	// 		interval = setInterval(() => {
	// 			setSeconds((sec) => sec + 1);
	// 		}, 1000);
	// 		if (seconds === 5) {
	// 			setIsActiveTimer(false);
	// 			setSeconds(0);
	// 			setFilterPanelOpen(false);
	// 		}
	// 	} else {
	// 		clearInterval(interval);
	// 	}

	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, [isActiveTimer, seconds]);
	const handleStartTimer = () => {
		setIsActiveTimer(true);
		setSeconds(0);
	};

	const handleFilterPanelOpen = () => {
		handleStartTimer();
		// setFilterPanelOpen(!filterPanelOpen);
	};
	const handleFilterPanelClose = () => setFilterPanelOpen(!filterPanelOpen);

	// const [filterMatrix, setFilterMatrix] = useState(tableStructure.map(() => ''));
	// const [sort, setSort] = useState<TSortState | null>(
	// 	navigateParams.sortIndex && navigateParams.sortDirection
	// 		? {
	// 				index: Number(navigateParams.sortIndex ?? 0),
	// 				direction: (navigateParams.sortDirection ?? 'asc') as TSortDirection,
	// 		  }
	// 		: null,
	// );
	const sort =
		navigateParams.sortIndex && navigateParams.sortDirection
			? {
					index: Number(navigateParams.sortIndex ?? 0),
					direction: (navigateParams.sortDirection ?? 'asc') as TSortDirection,
			  }
			: null;

	const filterMatrix = tableStructure.map((item, index) => {
		if (navigateParams[`filter${index}`]) return navigateParams[`filter${index}`] as string;
		return '';
	});

	const handleFilterMatrix = (index: number, value: string) => {
		const newNavigateParams = { ...navigateParams };
		if (value === 'All') {
			delete newNavigateParams[`filter${index}`];
		} else {
			newNavigateParams[`filter${index}`] = value;
		}

		let paramMatrix = '?';
		for (const [key, val] of Object.entries(newNavigateParams)) {
			paramMatrix += `${key}=${(val as string) ?? ''}&`;
		}
		paramMatrix = paramMatrix.slice(0, -1);
		const newFilterMatrix = [...filterMatrix];
		newFilterMatrix[index] = value === 'All' ? '' : value;
		// setFilterMatrix(newFilterMatrix);
		// setFilterPanelOpen(true);
		// handleStartTimer();
		navigate({
			pathname: location.pathname,
			search: paramMatrix,
		});
	};

	const handleSort = (index: number, direction: TSortDirection) => {
		const newNavigateParams = { ...navigateParams };
		if (direction === undefined) {
			delete newNavigateParams.sortIndex;
			delete newNavigateParams.sortDirection;
		} else {
			newNavigateParams.sortIndex = String(index);
			newNavigateParams.sortDirection = String(direction);
		}

		let paramMatrix = '?';
		for (const [key, val] of Object.entries(newNavigateParams)) {
			paramMatrix += `${key}=${(val as string) ?? ''}&`;
		}
		paramMatrix = paramMatrix.slice(0, -1);

		navigate({
			pathname: location.pathname,
			search: paramMatrix,
		});
	};

	return (
		<div>
			<Paper sx={{ mt: '-24px' }}>
				<TableContainer>
					<div
						style={{
							background: '#016193',
							padding: '10px 10px 0 10px',
							// display: 'flex',
							justifyContent: 'space-between',
							height: '35px',
							paddingBottom: '10px',
							display: filterPanelOpen ? 'flex' : 'none',
						}}
					>
						<IconButton onClick={handleFilterPanelClose} style={{ display: 'block' }}>
							<KeyboardArrowDownIcon style={{ color: '#fff' }} />
						</IconButton>
						{/* <div style={{ display: 'flex', justifyContent: 'normal' }}>
							<IconButton>
								<FilterListOffIcon style={{ color: '#fff' }} />
							</IconButton>
							<SearchComponent onChange={() => {}} search={searchStr} />
						</div> */}
					</div>

					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							{/* <TableRow sx={{ display: filterPanelOpen ? '' : 'none' }}>
								{tableStructure?.map((filter, index) => (
									<StyledTableCell
										key={`${String(tableType)}${filter.selector}${index}}`}
										// key={nanoid()}
										align="center"
										colSpan={filter.colSpan}
										style={{ backgroundColor: filterMatrix[index] ? '#015079' : '#006293' }}
									>
										{filter.filterable && (
											// <WhiteTextField
											// 	// key={nanoid()}
											// 	variant="outlined"
											// 	size="small"
											// 	sx={{ width: '120px' }}
											// 	value={filterMatrix[index]}
											// 	onChange={(e) => handleFilterMatrix(index, e.target.value)}
											// />
											<FilterField
												filterItem={filterMatrix[index]}
												filterList={
													tableFilters?.[
														tableFilters.findIndex((item) => item.selector === filter.selector)
													]?.filter
												}
												onChange={(value: string) => handleFilterMatrix(index, value)}
											/>
										)}
									</StyledTableCell>
								))}
							</TableRow> */}
							<TableRow
								style={{ height: '30px', display: filterPanelOpen ? 'none' : '' }}
								onClick={handleFilterPanelOpen}
							>
								{filterMatrix?.map((filter, index) => (
									<StyledTableCell
										// key={`small-filter-${index}`}
										key={nanoid()}
										align="center"
										colSpan={tableStructure?.[index]?.colSpan}
										style={{ padding: 0, backgroundColor: filter ? '#015079' : '#006293' }}
									>
										<b>{filter}</b>
									</StyledTableCell>
								))}
							</TableRow>
							<TableRow onClick={handleFilterPanelOpen}>
								{tableStructure?.map((filter, index: number) => (
									<StyledTableCell
										// key={filter.selector}
										key={nanoid()}
										align="center"
										colSpan={filter.colSpan}
										style={{
											backgroundColor:
												sort?.index === index
													? '#004468'
													: filterMatrix[index]
													? '#015079'
													: '#006293',
										}}
									>
										{filter.name}
										{(filter.filterable || filter.sortable) && (
											<>
												{sort?.index === index && sort?.direction === 'asc' ? (
													<ArrowDropUpIcon
														sx={{ display: 'inline', mb: '-8px', fontSize: 28 }}
														fontSize="small"
													/>
												) : sort?.index === index && sort?.direction === 'desc' ? (
													<ArrowDropDownIcon
														sx={{ display: 'inline', mb: '-8px', fontSize: 28 }}
														fontSize="small"
													/>
												) : (
													<MoreVertIcon sx={{ display: 'inline', mb: '-5px' }} fontSize="small" />
												)}
												<ActionSpeedDial
													filterable={filter.filterable}
													sortable={filter.sortable}
													filterItem={filterMatrix[index]}
													filterList={
														tableFilters?.[
															tableFilters.findIndex((item) => item.selector === filter.selector)
														]?.filter
													}
													onChangeFilter={(value: string) => handleFilterMatrix(index, value)}
													onChangeSort={(direction: TSortDirection) => handleSort(index, direction)}
													sortDirection={sort?.index === index ? sort?.direction : undefined}
												/>
											</>
										)}
									</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.map((item) => (
								<StyledTableRow hover key={item.id}>
									{tableStructure.map((filter, index) => (
										<StyledTableCell
											key={nanoid()}
											align="center"
											colSpan={filter.colSpan}
											sx={{
												maxWidth: '200px',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												backgroundColor:
													sort?.index === index
														? '#00000020'
														: filterMatrix[index]
														? '#0000000a'
														: '#00629300',
											}}
										>
											{filter.type === 'boolean' ? (
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore
												// item[filter.selector] ? (
												// 	<CheckCircle style={{ color: '#00b050' }} />
												// ) : (
												<CheckBoxOutlineBlank />
											) : filter.type === 'button' ? (
												<Button variant="contained" size="small">
													File Report
												</Button>
											) : (
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore
												item[filter.selector]
											)}
										</StyledTableCell>
									))}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};
