/* eslint-disable import/extensions */
import { LoginPage } from 'pages/LoginPage';
import { SubmitVATReportPage } from 'pages/SubmitVATReportPage';
import { useRoutes } from 'react-router';

export const NavigateList = () => {
	const routes = useRoutes([
		{
			path: `/login`,
			element: <LoginPage />,
		},
		{
			path: `/`,
			element: <SubmitVATReportPage />,
		},
		{
			path: `/:level1`,
			element: <SubmitVATReportPage />,
		},
		{
			path: `/:level1/:level2`,
			element: <SubmitVATReportPage />,
		},
		{
			path: `/:level1/:level2/:level3`,
			element: <SubmitVATReportPage />,
		},
	]);
	return routes;
};
